import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_b = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - B'/>
      <h1>Legal Latin maxims and expressions</h1>

     <h2>B</h2>
     <LatQuotesIntro />
    
     <p><b>Benignior sententia in verbis generalibus seu dubiis est preferenda</b> - The more favorable construction is to be placed on general or doubtful words.</p>
<p><b>Bis dat qui cito dat</b> - He gives (pays) twice who pays promptly. </p>
<p><b>Bona fide</b> - Sincere, in good faith</p>
<p><b>Bona vacantia</b> - Goods without an owner</p>
<p><b>Boni judicis est ampliare jurisdictionem</b> - It is the part of a good judge to enlarge his jurisdiction, i.e. remedial authority.</p>
<p><b>Boni judicis est judicium sine dilatione mandare executioni</b> - It is the duty of a good judge to cause execution to issue on a judgment without delay.</p>
<p><b>Boni judicis lites dirimere est</b> - It is the duty of a good judge to prevent litigation.</p>
<p><b>Bonus judex secundum aequum et bonum judicat et aequitatem stricto juri praefert</b> - A good judge decides according to justice and right and prefers equity to strict law.</p>
<p><b>Breve judiciale non cadit pro defectu formae</b> - A judicial writing does not fail through defect of form.</p>

   </Layout>
  )
}

export default LatQuotes_b
